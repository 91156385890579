<!--  -->
<template>
<div class="filePage">
    <div class="protocol-head">
        <div class="protocol-head-container flex-vc flex" >
            <a href="/" class="flex-vc flex">
                <div class="logo">
                    <img src="../../assets/img/default/upupoo.png">
                </div>
                <span class="text">upupoo</span>
            </a>
        </div>
    </div>
    <div class="protocol-layout-body">
        <h3>Upupoo官网内容审核规则</h3>
        <p class="protocal-title-bold">1、总则</p>
        <p>1.1 为加强公司网站的规范管理，建立规范的信息采集、审核、发布、更新已机制，做好信息舆论导向，促进网站健康发展，安全高效运行，结合公司实际，制定本制度。</p>
        <p>1.2 网站发布的任何信息都必须坚持正能量导向，遵守“九不准”要求和“七条底线”。</p>
        <p>1.3 网站发布的任何信息都必须遵守《中华人民共和国计算机系统安全保护已条例》、《中华人民共和国计算机信息网络国际互联网管理暂行规定》、《互联网信息服务制度》等有关国家法律法规规定。</p>
        <p>1.4 为规范网站信息采集、审核和发布机制，实行上网信息审批制度，坚持“先审后上、分级负责、保证质量”的原则，未经审核信息一律不准发布。下列信息不得在网上发布:有损国家、政府、人民利益信息﹔涉密信息﹔涉及商业秘密、个人隐私的相关信息;有损公司形象，对公司产生不利影响的信息。</p>
        <p>1.5 本制度适用于upupoo网站及下属upupoo桌面壁纸客户端、微博账号、微信公众号《以下均简称为upupoo）。c</p>
        <p class="protocal-title-bold">2、网站信息审核流程及标准</p>
        <p>2.1 上传至upupoo所有稿件一律先审后发，严格实行三审机制。运营部相关内容审核人员，要严格审核把关，对所发布信息内容的负责，未经审核人员审核的内容不得对外公开发布。</p>
        <p>（1）信息发布人为信息的直接责任人，发布人本人应对发布信息做第一道审核；</p>
        <p>（2）由upupoo内部审核人员对每个壁纸做第二道审核,普通稿件在经过二审之后可以发布；</p>
        <p>（3）遇到敏感、难以把握的稿件,将由upupoo审核负责人进行第三道审核后方可发布。</p>
        <p>2.2 Upupoo所有发布的壁纸应符合以下规范:</p>
        <p>（1）壁纸不能含有禁止发布的内容，包括但不限于反动、色情、恶心、宗教、政治及其他违
            反国家相关法律的内容等；</p>
        <p>（2）壁纸名称禁止包含敏感字/词，禁止填写无意义的、具有挑衅煽动的壁纸名称；</p>
        <p>（3）标签禁止包含敏感字/词，禁止填写反动、色情、恶心、宗教、政治及其他违反国家相关
            法律的内容等。</p>
        <p>2.3 严查以下不适宜发布内容:</p>
        <p>（1）带有反动、色情、恶心、宗教、政治及其他违反国家相关法律内容的；</p>
        <p>（2）带有可能造成用户身体不适、其他临床症状的、或过度猎奇恶心的内容；</p>
        <p>（3）其他涉及违反有关部门、条例规定要求的；</p>
        <p>（4）具有较大争议性的。</p>
        <p>2.4 禁止发布违反“九不准“七条底线规定的内容</p>
        <p>2.4.1 互联网信息管理“九不准”:</p>
        <p>（1）反对宪法所确定的基本原则的；</p>
        <p>（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
        <p>（3）损害国家荣誉和利益的；</p>
        <p>（4）煽动民族仇恨、民族歧视，破坏民族团结的；</p>
        <p>（5）破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
        <p>（6）散步谣言，扰乱社会秩序，破坏社会稳定的；</p>
        <p>（7）散步淫秽、色情、赌博、暴力、凶杀、恐怖或者教峻犯罪的；</p>
        <p>（8）侮辱或者谁谤他人，侵害他们合法权益的；</p>
        <p>（9）含有法律、行政法规禁止的其他内容的。</p>
        <p>2.4.2 中国互联网大会倡议的“七条底线”:</p>
        <p>（1）法律法规底线；</p>
        <p>（2）社会主义制度底线；</p>
        <p>（3）国家利益底线；</p>
        <p>（4）公民合法权益底线；</p>
        <p>（5）社会公共秩序底线；</p>
        <p>（6）道德风尚底线；</p>
        <p>（7）信息真实性底线。</p>
        <p>2.5 Upupoo有权根据法律、国家有权机构或公司经营要求修改本规则的有关内容,并会通过适 当方式在网站上予以公示。←</p>
    </div>
</div>
</template>

<script>
export default {
    name: '',
    components: {},
    data() {
        return {

        };
    },
    computed: {},
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.filePage {
  background: #fff;
  width: 100%;
  height: 100%;

  .h-bga {
    background: none;
    overflow-x: hidden;
  }

  .protocol-head {
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(85, 85, 85, 0.1);
  }

  .protocol-head-container {
    width: 1080px;
    height: 60px;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }

  .protocol-head-container a {
    display: flex;
    align-items: center;
  }

  .protocol-head-container .logo {
    width: 42px;
    height: 42px;
    line-height: 42px;
    margin-right: 10px;
    text-align: center;
    font-size: 0;
    display: inline-block;
  }

  .protocol-head-container img {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
  }

  .protocol-head-container .text {
    font-size: 20px;
    color: #4a4a4a;
    line-height: 60px;
  }

  .protocol-layout-body {
    width: 1080px;
    margin: 22px auto 0;
    min-height: 886px;
    color: #000;
    font-size: 16px;
  }

  .protocol-layout-body h3 {
    padding: 4px 0 24px;
    font-size: 24px;
    border-bottom: 1px solid #e7e7eb;
    margin-bottom: 24px;
  }

  .protocol-layout-body p {
    margin-bottom: 20px;
    line-height: 24px;
  }

  .protocal-title-bold {
    font-weight: bold;
  }
}
</style>
